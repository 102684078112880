import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import Loadingfilter from "./Loadingfilter";

const ProdFilter = ({
  handleCategoryChange,
  handleTagChange,
  selectedCategory,
  selectedTag,
}) => {
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const consumerKey = 'ck_a5217f627b385dde1c5d2392aae81f5244ce0af5';
    const consumerSecret = 'cs_70ed7d3b65ccb71cf9cbf49f6bd064cd25402bca';

    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/wp-json/wc/v3/products/categories?per_page=50&consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`
        );
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        return data.filter(category => category.id !== 100); // Filter out the category with ID 100
      } catch (error) {
        console.error("Fetch Error:", error);
        return [];
      }
    };

    const fetchTags = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/wp-json/wc/v3/products/tags?per_page=50&consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`
        );
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Fetch Error:", error);
        return [];
      }
    };
    
    const fetchData = async () => {
      const [categoriesData, tagsData] = await Promise.all([fetchCategories(), fetchTags()]);
      setCategories(categoriesData);
      setTags(tagsData);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm)
  );

  const filteredTags = tags.filter((tag) =>
    tag.name.toLowerCase().includes(searchTerm)
  );

  if (loading) {
    return <Loadingfilter />;
  }

  return (
    <Box bg="white" boxShadow="lg" borderRadius="md" p={4}>
      <InputGroup mb={4}>
        <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.400" />} />
        <Input
          type="text"
          placeholder="Search categories and tags"
          value={searchTerm}
          onChange={handleSearchChange}
          variant="outline"
          focusBorderColor="teal.500"
        />
      </InputGroup>
      <Accordion defaultIndex={[0, 1]} allowMultiple w="100%">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight="500" color="gray.500">
                <Text fontWeight="bold" mb="3px" color="gray.600" fontSize="15px">
                  Categories
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} color="gray.500" p="2">
            <RadioGroup onChange={handleCategoryChange} value={selectedCategory}>
              <Stack direction="column" gap="2">
                {filteredCategories.map((category) => (
                  <Radio value={category.id.toString()} key={category.id}>
                    {category.name}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight="500" color="gray.500">
                <Text fontWeight="bold" mb="3px" color="gray.600" fontSize="15px">
                  Tags
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} color="gray.500" p="2">
            <RadioGroup onChange={handleTagChange} value={selectedTag}>
              <Stack direction="column" gap="2">
                <Radio value="" key="all">
                  All Tags
                </Radio>
                {filteredTags.map((tag) => (
                  <Radio value={tag.id.toString()} key={tag.id}>
                    {tag.name}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default ProdFilter;
