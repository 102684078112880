export const services = [
  {
    labels: "Privacy Policy",
    src:"/PP"
  },
  {
    labels: "Terms & Condition",
    src:"/TC"
  },
  {
    labels: "Cancellation & Return",
    src:"/CR"
  },
  {
    labels: "Shipping & Delivery",
    src:"/SD"
  },
];

export const about = [
  {
    labels: "Phone : +91 95102 24663",
  },
  {
    labels: "E-Mail: Info@Lincolneyewear.Com",
  },
];

