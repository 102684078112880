import React, { useState, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../ContextApi/AuthContext";
import { FiPhoneCall } from "react-icons/fi";
import { TbHeartFilled } from "react-icons/tb";
import { TbShoppingCartFilled } from "react-icons/tb";
import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Flex,
  Image,
  Input,
  Button,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Tooltip,
} from "@chakra-ui/react";
import logotop from "../../Images/logotop.png";
import Login from "../../Pages/Login/Login";
import Signup from "../../Pages/Signup/Signup";
import { useSearch } from '../../Context/SearchContext';
import NavbarCard5 from "./NavbarCard5";
import { TbUserFilled } from "react-icons/tb";

export const NavbarCard2 = () => {
  const { isAuth, setisAuth, Authdata } = useContext(AuthContext);
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setSearchValue, searchValue } = useSearch();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && location.pathname !== '/products') {
      navigate(`/products?search=${searchValue}`);
    }
  };

  const getInitials = (name) => {
    const words = name.split(' ');
    if (words.length === 1) {
      return name;
    }
    return words.map(word => word.charAt(0).toUpperCase()).join('');
  };

  return (
    <Box>
      <HStack m="auto">
        <Box w="20%">
          <Link to="/">
            <Image src={logotop} alt="logo" width="200px" />
          </Link>
        </Box>
        <HStack w="80%" m="auto" justify="space-between">
          <Box w="50%">
            <Input
              placeholder="Search for Eyeglasses, Sunglasses and more.."
              border={isFocused ? "1px solid green" : "1px solid #616569"}
              backgroundColor="white"
              fontSize="17px"
              h="45px"
              pl="4"
              borderRadius="7px"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={handleKeyDown}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </Box>
          <HStack w="45%" justify="flex-end" spacing="10px">
            <Button onClick={() => navigate("/orderhistory")} h="45px"
                  px="20px"
                  bg="whiteAlpha.900"
                  fontSize="15px"
                  fontWeight="700"
                  border="1px solid"
                  borderColor={"secondary"}
                  _hover={{ bg: "secondary", color: "white", boxShadow: "md" }}
                  _focus={{ boxShadow: "outline" }}
                  transition="all 0.2s ease-in-out"
                  color="secondary">
              Track Order
            </Button>

            {isAuth ? (
              <Popover trigger="hover" placement="bottom-end">
              <PopoverTrigger>
                <Button
                  h="45px"
                  px="20px"
                  bg="whiteAlpha.900"
                  fontSize="15px"
                  fontWeight="600"
                  border="1px solid"
                  borderColor={"secondary"}
                  _hover={{ bg: "secondary", color: "white", boxShadow: "md" }}
                  _focus={{ boxShadow: "outline" }}
                  transition="all 0.2s ease-in-out"
                  color="secondary"
                >
                  <TbUserFilled size={25}></TbUserFilled>
                  <TriangleDownIcon ml="6px" fontSize="9px" />
                </Button>
              </PopoverTrigger>
            
              <PopoverContent
                w="125px"
                boxShadow="rgba(0, 0, 0, 0.15) 0px 4px 12px"
                borderRadius="8px"
                bg="white"
                border="none"
              >
                <PopoverBody
                h="50px"
                fontSize="14px"
                fontWeight="500"
                pl="20px"
                display="flex"
                alignItems="center">
                {getInitials(Authdata.user_display_name)}
                </PopoverBody>
                <PopoverBody
                  h="50px"
                  fontSize="14px"
                  fontWeight="500"
                  pl="20px"
                  display="flex"
                  alignItems="center"
                  cursor="pointer"
                  _hover={{ bg: "secondary", color: "white" }}
                  transition="background 0.3s ease-in-out"
                  onClick={() => {
                    setisAuth(false);
                    localStorage.clear();
                    navigate("/");
                  }}
                >
                  Sign Out
                </PopoverBody>
              </PopoverContent>
            </Popover>
            
            ) : (
              <HStack spacing="10px">
                <Tooltip label="Sign In" fontSize="15px" bg="secondary">
                  <Button
                    h="45px"
                    px="20px"
                    bg="whiteAlpha.900"
                    fontSize="15px"
                    fontWeight="600"
                    border="1px solid"
                    borderColor={"secondary"}
                    _hover={{ bg: "secondary", color: "white" }}
                    transition="0.3s"
                    color="secondary" 
                  ><Login /></Button>
                </Tooltip>
                <Tooltip label="Sign Up" fontSize="15px" bg="secondary">
                  <Button
                    h="45px"
                    px="20px"
                    bg="whiteAlpha.900"
                    fontSize="15px"
                    fontWeight="600"
                    border="1px solid"
                    borderColor={"secondary"}
                    _hover={{ bg: "secondary", color: "white" }}
                    transition="0.3s"
                    color="secondary" 
                  ><Signup /></Button>
                </Tooltip>
              </HStack>
            )}

            {/* Wishlist Icon with Tooltip */}
            <Tooltip label="Wishlist" fontSize="15px" bg="secondary">
              <Button
                h="45px"
                px="20px"
                bg="whiteAlpha.900"
                fontSize="15px"
                fontWeight="600"
                border="1px solid"
                borderColor={"secondary"}
                _hover={{ bg: "secondary", color: "white" }}
                transition="0.3s"
                onClick={() => navigate("/wishlist")}
                color="secondary" 
              >
                <TbHeartFilled size={25}/>
              </Button>
            </Tooltip>

            {/* Cart Icon with Tooltip */}
            <Tooltip label="Cart" fontSize="15px" bg="secondary">
              <Button
                h="45px"
                px="20px"
                bg="whiteAlpha.900"
                fontSize="15px"
                fontWeight="600"
                border="1px solid"
                borderColor={"secondary"}
                _hover={{ bg: "secondary", color: "white" }}
                transition="0.3s"
                onClick={() => navigate("/cart")}
                color="secondary" 
              >
                <TbShoppingCartFilled size={25}/>
              </Button>
            </Tooltip>
          </HStack>
        </HStack>
      </HStack>
    </Box>
  );
};

export const NavbarCard4 = () => {
  return (
    <Box mt="1rem" bg="secondary" borderRadius="8px" display="flex" justifyContent="center">
      <Flex py={2} px={5}>
        <NavbarCard5 />
      </Flex>
    </Box>
  );
};
