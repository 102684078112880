import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import Webcam from 'react-webcam';
import * as THREE from 'three';
import * as tf from '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-converter';
import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-wasm';
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import { Box, Center, Button, useMediaQuery, Spinner, Text, Alert, AlertIcon, useToast } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

const VirtualTryOn = forwardRef((props, ref) => {
  const { isOpen, imageSrc, onClose } = props;
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [model, setModel] = useState(null);
  const [glassesMesh, setGlassesMesh] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFaceModelLoading, setIsFaceModelLoading] = useState(true);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [error, setError] = useState(null);
  const toast = useToast();  // Chakra UI's toast for notifications

  useImperativeHandle(ref, () => ({
    stopWebcam
  }));

  const loadModelWithFallback = async () => {
    let backendLoaded = false;

    try {
      await tf.setBackend('wasm');
      await tf.ready();
      backendLoaded = true;
      console.log('Using TensorFlow.js backend: wasm');
    } catch (error) {
      console.warn('WASM failed to load, falling back to WebGL');
      try {
        await tf.setBackend('webgl');
        await tf.ready();
        backendLoaded = true;
        console.log('Using TensorFlow.js backend: webgl');
      } catch (error) {
        console.error('WebGL failed, falling back to CPU');
        await tf.setBackend('cpu');
        await tf.ready();
      }
    }

    if (!backendLoaded){
      toast({
        title: 'No supported backend found for TensorFlow.js',
        description: 'Enable Webgl in settings.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      onClose(); 
      stopWebcam();
      return;
    }

    const loadedModel = await faceLandmarksDetection.load(
      faceLandmarksDetection.SupportedPackages.mediapipeFacemesh,
      { shouldLoadIrisModel: true, maxFaces: 1 }
    );

    return loadedModel;
  };

  const loadResources = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (webcamRef.current) {
        webcamRef.current.srcObject = stream;
      }

      const loadedModel = await loadModelWithFallback();
      setModel(loadedModel);

      const width = canvasRef.current.clientWidth;
      const height = canvasRef.current.clientHeight;
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
      camera.position.z = 5;
      const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true });
      renderer.setSize(width, height);
      renderer.setAnimationLoop(() => renderer.render(scene, camera));

      const proxyUrls = [
        'https://api.allorigins.win/raw?url=',
        'https://cors-anywhere.herokuapp.com/',
        'https://thingproxy.freeboard.io/fetch/',
      ];

      const textureLoader = new THREE.TextureLoader();
      const loadTextureWithFallback = (index = 0, retries = 3) => {
        if (index >= proxyUrls.length) {
          // Close the modal and show toast if all proxies fail
          setError('Failed to load glasses texture. Please try again.');
          toast({
            title: 'Error loading eyeglasses',
            description: 'Failed to load eyeglasses texture. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          onClose(); 
          stopWebcam();
          return;
        }

        const currentProxyUrl = proxyUrls[index];
        textureLoader.load(
          currentProxyUrl + imageSrc,
          (texture) => {
            texture.colorSpace = THREE.SRGBColorSpace;
            const geometry = new THREE.PlaneGeometry(1.85, 1);
            const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
            const glasses = new THREE.Mesh(geometry, material);
            scene.add(glasses);
            setGlassesMesh(glasses);
          },
          undefined,
          (error) => {
            if (retries > 0) {
              console.error(`Texture load error with proxy ${currentProxyUrl}: ${error}. Retrying... (${retries})`);
              loadTextureWithFallback(index, retries - 1);
            } else {
              console.error(`Failed to load texture with proxy ${currentProxyUrl}. Trying next proxy...`);
              loadTextureWithFallback(index + 1);
            }
          }
        );
      };

      loadTextureWithFallback();
    } catch (error) {
      console.error("Initialization error:", error);
      setError('Failed to initialize the virtual try-on. Please reload the page.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadResources();
    } else {
      stopWebcam();
    }
    return () => stopWebcam();
  }, [isOpen, imageSrc]);

  useEffect(() => {
    let animationFrameId;
    const detectAndPositionGlasses = async () => {
      if (!webcamRef.current || !model || !glassesMesh) return;

      const video = webcamRef.current.video;
      if (video.readyState !== 4) return;

      try {
        const faceEstimates = await model.estimateFaces({ input: video });
        if (faceEstimates.length > 0) {
          const keypoints = faceEstimates[0].scaledMesh;

          const leftEar = keypoints[234];  // Left ear landmark
          const rightEar = keypoints[454]; // Right ear landmark
          const earDistance = Math.sqrt(
            Math.pow(rightEar[0] - leftEar[0], 2) + Math.pow(rightEar[1] - leftEar[1], 2)
          );

          const scaleMultiplier = earDistance / 160;

          const eyeCenter = keypoints[168]; 
          const scaleX = -0.01;
          const scaleY = -0.01;
          const offsetX = 0.00;
          const offsetY = -0.06;

          glassesMesh.position.x = (eyeCenter[0] - video.videoWidth / 2) * scaleX + offsetX;
          glassesMesh.position.y = (eyeCenter[1] - video.videoHeight / 2) * scaleY + offsetY;
          glassesMesh.scale.set(scaleMultiplier, scaleMultiplier, scaleMultiplier);
          glassesMesh.position.z = 1;

          const faceLine = new THREE.Vector2(rightEar[0] - leftEar[0], rightEar[1] - leftEar[1]);
          const rotationZ = Math.atan2(faceLine.y, faceLine.x);
          glassesMesh.rotation.z = rotationZ;

          setIsLoading(false);
          setIsFaceModelLoading(false);
        }
      } catch (error) {
        console.error("Face detection error:", error);
        setError('Failed to detect face. Please ensure your face is fully visible and retry.');
      }
      animationFrameId = requestAnimationFrame(detectAndPositionGlasses);
    };

    if (model && glassesMesh) {
      detectAndPositionGlasses();
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [model, glassesMesh]);

  const stopWebcam = () => {
    if (webcamRef.current && webcamRef.current.srcObject) {
      const stream = webcamRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      webcamRef.current.srcObject = null;
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '90vh',
        background: 'linear-gradient(135deg, #f8f9fa, #e9ecef)',
        overflow: 'hidden',
        borderRadius: '20px',
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
        display: 'flex',
        flexDirection: "column",
        alignContent: 'center',
        justifyContent: 'center'
      }}
    >
      <div style={{ position: 'relative', margin: '0 auto', width: isMobile?'340px':'800px', height: isMobile?'340px':'800px' }}>
        {isLoading && !error && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2,
            }}
          >
            <Spinner size="xl" color="blue.500" />
            <Text mt={4} fontSize="lg" color="#333">Loading...</Text>
            <Box mt={4} textAlign="center" color="#666">
              <Text fontSize="16px">💡 Be in a well-lit area.</Text>
              <Text fontSize="16px">👀 Ensure your face is fully visible and centered.</Text>
              <Text fontSize="16px">🚫 Avoid shadows and glare.</Text>
            </Box>
          </div>
        )}
        <Webcam ref={webcamRef} autoPlay playsInline style={{ width: isMobile?'340px':'800px', height: isMobile?'340px':'800px' }} mirrored={true} />
        <canvas ref={canvasRef} style={{ width: isMobile?'340px':'800px', height: isMobile?'340px':'800px', position: 'absolute', top: 0, left: 0 }} />
      </div>
      <Button
        onClick={() => {
          onClose();
          stopWebcam();
        }}
        position="absolute"
        top="20px"
        right="15px"
        variant="ghost"
        zIndex={3}
        style={{
          backgroundColor: '#d32f2f',
          color: '#fff',
          borderRadius: '50%',
          padding: '10px',
          boxShadow: '0 6px 10px rgba(0, 0, 0, 0.15)', 
        }}
        cursor="pointer"
      >
        <CloseIcon boxSize={5} color="#fff" />
      </Button>
    </div>
  );
});

export default VirtualTryOn;
