export const data = {
  "Single Vision": [
    {
      id: " 4604",
      name: "Anti-Glare Regular",
      features: [
        "Premium quality Anti reflective coated glass",
        "6 Months Warranty",
        "Adjusted Lens Thickness (for powers upto -12)",
        "Single-Side Anti-Glare",
        "Free Lens",
      ],
      price: "Free",
      src: ""
    },
    {
      id: "4605",
      name: "Anti-Glare Pro",
      features: [
        "Ultra edge lenses with Anti reflective coating",
        "6 Months Warranty",
        "Adjusted Lens Thickness (for powers upto -12)",
        "Double-Side Anti-Glare",
        "Scratch Resistant",
      ],
      price: "275",
      src: ""
    },
    {
      id: "4606",
      name: "BLU Essential",
      features: [
        "1 Year Warranty",
        "Premium Blue Block & Anti-Glare",
        "Adjusted Lens Thickness (for powers upto -12)",
        "Double-Side Anti-Glare"
      ],
      price: "1100",
      src: ""
    },
    {
      id: "4607",
      name: "Ultra BLU Thin",
      head:"MAXIMUM EYE PROTECTION",
      features: [
        "1 Year Warranty",
        "Ultra thin Blue Block & Anti-Glare",
        "Adjusted Lens Thickness (for powers upto -12)",
        "Double-Side Anti-Glare",
        "Crack, Smudge & Scratch Resistant",
        "Water & Dust Repellent",
        "UV 420 - Protection"
      ],
      price: "1900",
      src: ""
    },
    {
      id: "4608",
      name: "Ultra BLU Thin",
      head:"MAXIMUM EYE PROTECTION (Premium)",
      features: [
        "Premium quality vision ",
        "Specially designed in Korea",
        "1 Year Warranty",
        "Blue Light Block & Anti-Glare coating",
        "Adjusted Lens Thickness (for powers upto -12)",
        "Double-Side Anti-Glare",
        "Crack Resistant",
        "Water & Dust Repellent",
        "UV 420 - Protection"
      ],
      price: "2499",
      src: ""
    },
    {
      id: "4609",
      name: "Owndays Shatterproof",
      head:"DESIGNED IN KOREA",
      features: [
        "1 Year Warranty",
        "Blue Light Block & Anti-Glare coating",
        "Adjusted Lens Thickness (for powers upto -12)",
        "Double-Side Anti-Glare",
        "Smudge & Scratch Resistant",
        "Water & Dust Repellent",
        "UV 420 - Protection"
      ],
      price: "2499",
      src: ""
    },
    {
      id: "4610",
      name: "Owndays Ultra Thin",
      head:"DESIGNED IN KOREA",
      features: [
        "1 Year Warranty",
        "Blue Light Block & Anti-Glare coating",
        "Ultra Thin with 1.63 Index",
        "Double-Side Anti-Glare",
        "Crack Resistant",
        "Water & Dust Repellent",
        "UV 420 - Protection"
      ],
      price: "3999",
      src: ""
    },
  ],
  "Bifocal/ Progressive": [
    {
      id: "4611",
      name: "Standard Bifocal",
      features: [
        "1 Year Warranty",
        "Circular reading area in lower part",
        "Non Anti-Glare",
      ],
      price: "1100",
      src: ""
    },
    {
      id: "4612",
      name: "Circular Bi-Focal KT",
      features: [
        "1 Year Warranty",
        "Circular reading area in lower part",
        "Anti-Glare Lens",
        "Crack Resistant",
        "Water & Dust Repellent",
        "UV 400 Protection",
        "Power Range: SPH (+/-3 to +/-6) & CYL (+/-4) * For any other combination, opt either Bifocal-D or Progressives"
      ],
      price: "2100",
      src: "/images/0.jpg"
    },
    {
      id: "4613",
      name: "Standard Progressive",
      features: [
        "1 Year Warranty",
        "Near to Far Progression",
        "Non Anti-Glare",
      ],
      price: "1899",
      src: "/images/2.jpg"
    },
    {
      id: "4615",
      name: "Normal Corridor Progressive",
      head:"MAXIMUM EYE PROTECTION",
      features: [
        "1 Year Warranty",
        "Near To Far Progression",
        "Anti-Glare Lens",
        "Adjusted Lens Thickness (for powers upto -12)",
        "Water & Dust Repellent",
        "Scratch & Crack Resistant",
        "UV Protection",
        "Green anti glare coating"
      ],
      price: "2499",
      src: "/images/3.jpg"
    },
    {
      id: "4616",
      name: "Normal Corridor",
      head:"BLU Progressive: MAXIMUM EYE PROTECTION",
      features: [
        "Ultra premium quality",
        "1 Year Warranty",
        "Near To Far Progression",
        "Anti-Glare Lens",
        "Adjusted Lens Thickness (for powers upto -12)",
        "Water & Dust Repellent",
        "Scratch & Crack Resistant",
        "UV Protection",
      ],
      price: "3500",
      src: "/images/4.jpg"
    },
    {
      id: "4617",
      name: "Digital Owndays",
      head:"Wide Corridor Progressive: DESIGNED IN KOREA",
      features: [
        "1 Year Warranty",
        "Near To Far Progression",
        "Anti-Glare Lens",
        "Thinnest Lens As Per Your Power",
        "Water & Dust Repellent",
        "UV 400 Protection",
      ],
      price: "5999",
      src: "/images/5.jpg"
    },
  ],
  "Zero Power": [
    {
      id: "4619",
      name: "BLU Thin Zero Power",
      features: [
        "1 Year Warranty",
        "Blue Block & Anti-Glare",
        "Adjusted Lens Thickness (for powers upto -12)",
        "Double-Side Anti-Glare",
        "Crack, Smudge & Scratch Resistant",
        "Water & Dust Repellent",
        "UV 420 - Protection",
      ],
      price: "275",
      src: ""
    },
    {
      id: "4625",
      name: "Anti-Fog BLU Thin",
      head:"Zero Power",
      features: [
        "1 Year Warranty",
        "Anti-Fog, Blue Block & Anti-Glare",
        "Double Side Anti-Fog & Anti-Glare",
        "Fog, Crack, Scratch & Smudge Resistant",
        "Water & Dust Repellent",
        "UV 420 - Protection",
        "Exceptional optical & visual clarity",
      ],
      price: "899",
      src: ""
    },
    {
      id: "4620",
      name: "Owndays Clear Vision",
      head:"Zero Power",
      subtitle: "DESIGNED IN KOREA",
      features: [
        "1 Year Warranty",
        "Blue Light Block & Anti-Glare coating",
        "Double-Side Anti-Glare",
        "Crack Resistant",
        "Water & Dust Repellent",
        "UV 420 - Protection",
        "Night Drive Coating",
      ],
      price: "1099",
      src: ""
    },
  ],
  "Sunglasses Polarised": [
    {
      id: "4621",
      name: "Brilliant Brown Sun block",
      features: [
        "1 Year Warranty",
        "Polarized Brown color Lenses",
        "UV 400 Protection",
        "Lens Material: CR39",
        "Index is auto-adjusted",
      ],
      price: "1299",
      src: ""
    },
    {
      id: "4622",
      name: "Gorgeous Grey Sun block",
      features: [
        "1 Year Warranty",
        "Polarized Grey Color Lenses",
        "UV 400 Protection",
        "Lens Material: CR39",
        "Index is auto-adjusted",
      ],
      price: "1299",
      src: "/images/10.jpg"
    },
    {
      id: "4623",
      name: "Glorious Green Sun block",
      features: [
        "1 Year Warranty",
        "Polarized Green Color Lenses",
        "UV 400 Protection",
        "Lens Material: CR39",
        "Index is auto-adjusted",
      ],
      price: "1299",
      src: "/images/11.jpg"
    },
    {
      id: "4624",
      name: "Cool Blue Sun block",
      features: [
        "1 Year Warranty",
        "Polarized Blue Color Lenses",
        "UV 400 Protection",
        "Lens Material: CR39",
        "Index is auto-adjusted",
      ],
      price: "1299",
      src: "/images/12.jpg"
    },
  ],
};


