import { Flex } from "@chakra-ui/react";
import React from "react";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const Loadingimg = () => {
  return (
    <Flex
      width="100%"
      height="100%"
      align="center"
      justify="center"
    >
      <DotLottieReact
        src="https://lottie.host/7a2ca4c0-d3bd-4292-b02e-10f9c056aeef/D5ZpetxOX1.json"
        loop
        autoplay
        speed={1}
        color="#616569"
      />
    </Flex>
  );
};

export default Loadingimg;
