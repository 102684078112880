import React, { useRef } from 'react';
import {
  Box,
  Button,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Tooltip,
  Icon
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import SelectLens from "../Lenses/SelectLens";
import VirtualTryOn from "../../Components/Tryon/tryOn";  // Adjust the import path as necessary
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { TbHeart } from 'react-icons/tb';
import { FaCameraRetro } from 'react-icons/fa';
const ProdCard = ({
  type,
  handleCart,
  handleWishlist,
  handleLensCart,
  selectedLens,
  totalPrice,
  virtualTryOnImage
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isTryOnOpen, onOpen: onTryOnOpen, onClose: onTryOnClose } = useDisclosure();
  const tryOnRef = useRef(null);
  const [selectedLensName, setSelectedLensName] = React.useState("Select Lens");
  const navigate = useNavigate();

  const handleLensClick = (lens) => {
    handleLensCart(lens);
    setSelectedLensName(lens.name);
    onClose();
  };

  const buttonStyles = {
    mt: 2,
    p: { lg: 7, base: 0 },
    m: { lg: "10px 20px", base: "10px auto" },
    w: { lg: "90%", base: "100%" },
    color: "white",
    _hover: { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.4)" },
    textAlign: "center",
    fontSize: {
      xs: 14,
      md: 16,
    },
  };

  const handleTryOnClick = () => {
    onTryOnOpen();
  };

  const handleTryOnClose = () => {
    if (tryOnRef.current) {
      tryOnRef.current.stopWebcam();
    }
    onTryOnClose();
  };

  return (
<Box bg="white" p={6} boxShadow="lg" borderRadius="lg" maxW="100%" display="flex" flexDirection="column">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
      >
        <Box>
          <Text
            fontSize={{ base: "lg", md: "xl" }}
            lineHeight="1.5"
            fontWeight="700"
            textTransform="capitalize"
            color="gray.800"
          >
            {type.name}
          </Text>

          <Text my="5px" fontWeight="600" color="gray.500" fontSize={{ base: "14px", md: "15px" }}>
            Size: Standard <Box flexBasis={{ base: "100%", md: "30%" }} mt={{ base: 0, md: 2 }} color={type.stock_status === "instock" ? "green.500" : "red.500"}>
          <Flex align="center">
            <Text fontSize={{ base: "14px", md: "15px" }} fontWeight="bold" mr={2}>
              {type.stock_status === "instock" ? "In Stock" : "Out of Stock"}
            </Text>
            {type.stock_status === "instock" ? (
              <CheckIcon boxSize={4} color="green.500" />
            ) : (
              <CloseIcon boxSize={4} color="red.500" />
            )}
          </Flex>
        </Box>
          </Text>

          <Text my="10px" fontWeight="500" fontSize={{ base: "16px", md: "18px" }} color="teal.500">
            ₹{type.sale_price}
            <span
              style={{
                fontSize: "14px",
                fontWeight: "lighter",
                color: "#727297",
                textDecoration: "line-through",
                marginLeft: "10px",
              }}
            >
              ₹{type.regular_price}
            </span>
          </Text>
        </Box>

      </Flex>

      {/* Buttons for Try On and Wishlist */}
      <Flex alignItems="center" justifyContent="space-around" w="100%">
        <Tooltip label="Add to Wishlist" fontSize="15px" bg="secondary">
          <Button
            onClick={() => handleWishlist(type)}
            colorScheme="teal"
            variant="ghost"
            borderRadius="full"
            p={2}
            mx={{ base: 2, md: 0 }}
          >
            <TbHeart size={30} />
          </Button>
        </Tooltip>

        <Tooltip label="Virtual Try-On" fontSize="15px" bg="secondary">
          <Button
            onClick={handleTryOnClick}
            colorScheme="teal"
            variant="solid"
            mt={{ base: 0, md: 2 }}
            p={2}
            borderRadius="lg"
          >
            <Icon as={FaCameraRetro} boxSize={6} mr={2} />
            Try On
          </Button>
        </Tooltip>
      </Flex>

      <Button sx={buttonStyles} onClick={onOpen} colorScheme='teal'>
        {selectedLensName}
      </Button>
      <SelectLens isOpen={isOpen} onClose={onClose} handleLensCart={handleLensClick} />

      {selectedLens ? (
        <Button sx={buttonStyles} mt={2} onClick={() => handleCart(type)} colorScheme='teal' isDisabled={type.stock_status !== "instock"}>
          Add to Cart - ₹{totalPrice}
        </Button>
      ) : (
        <Button sx={buttonStyles} onClick={() => handleCart(type)} colorScheme='teal' isDisabled={type.stock_status !== "instock"}>
          Purchase without Lenses
        </Button>
      )}

      <Accordion allowMultiple defaultIndex={[0]}>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left" my="10px"
              fontWeight={"700"}
              fontSize="lg">
              Short Description
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <div dangerouslySetInnerHTML={{ __html: type.description }} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left" my="10px"
              fontWeight={"700"}
              fontSize="lg">
              Categories
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {type.categories?.map((category) => (
              <Text key={category.id}>{category.name}</Text>
            )) || <Text>No categories available</Text>}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left" my="10px"
              fontWeight={"700"}
              fontSize="lg">
              Tags
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {type.tags?.map((tag) => (
              <Text key={tag.id}>{tag.name}</Text>
            )) || <Text>No tags available</Text>}
          </AccordionPanel>
        </AccordionItem>

      </Accordion>

      {/* Modal for Virtual Try-On */}
      <Modal isOpen={isTryOnOpen} onClose={handleTryOnClose} size="xl">
        <ModalOverlay />
        <ModalContent
          maxWidth={{ base: "100vw", md: "95vw" }}
          maxHeight={{ base: "100vh", md: "95vh" }}
          overflow="hidden"
          mt={{ base: "5%", md: "5%" }}
          mb="5%"
          boxShadow="2xl"
          bg="transparent"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <ModalBody padding="5%">
            <VirtualTryOn isOpen={isTryOnOpen} onClose={handleTryOnClose} imageSrc={virtualTryOnImage} />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProdCard;