import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { NavbarCard2, NavbarCard4 } from "./NavbarCard";
import Nav from "./Nav";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* Mobile Navbar */}
      <Box
        display={{ base: "block", xl: "none" }}
        bg="white"
        position="sticky"
        top="0"
        zIndex="1000"
        boxShadow={scrolled ? "0 4px 6px rgba(0, 0, 0, 0.25)" : "none"}
        transition="box-shadow 0.3s ease-in-out"
      >
        <Nav />
      </Box>

      {/* Desktop Navbar */}
      <Box
        display={{ base: "none", xl: "block" }}
        bg="white"
        position="sticky"
        top="0"
        zIndex="1000"
        boxShadow={scrolled ? "0 4px 8px rgba(0, 0, 0, 0.25)" : "none"}
        transition="all 0.3s ease-in-out"
        height={scrolled ? "80px" : "170px"}
      >
        <Box
          height="100%"
          px={4}
          bg="aliceblue"
          transition="all 0.3s ease"
          position="relative"
        >
          <Box>
            <NavbarCard2 />

            {/* NavbarCard4 Visibility Control */}
            <Box
              opacity={scrolled ? 0 : 1}
              maxHeight={scrolled ? "0" : "88px"}
              overflow="hidden"
              transition="opacity 0.5s ease, max-height 0.5s ease"
            >
              <NavbarCard4 />
            </Box>
          </Box>
        </Box>
        <Nav />
      </Box>
    </>
  );
};

export default Navbar;
